<template>
  <div>
    <v-container class="mt-8 mb-8">
      <div class="cart-details">
        <div class="row">
          <div class="col-12">
            <div>
              <OrderDetailsTable
                :cart-items="cartItems"
                class="d-none d-md-block"
              />

              <OrderDetailsMobile
                class="d-md-none"
                :cart-items="cartItems"
              />

              <div class="button-container mb-6 mt-10">
                <v-btn
                  color="secondary"
                  outlined
                  class="px-0 px-md-3 back-btn"
                  @click="backToPreviousPage"
                >
                  <v-icon>
                    mdi-arrow-left-circle
                  </v-icon>
                  <span class="d-none d-md-inline-block ml-md-2">Back</span>
                </v-btn>
                <div
                  :class="['d-flex align-end',
                           { 'justify-end': !!cartItems.length },
                           { 'justify-center': cartItems.length === 0 }
                  ]"
                >
                  <v-btn
                    color="secondary"
                    outlined
                    :to="{name: 'Products'}"
                    class="mr-2 px-3"
                  >
                    Shop More
                  </v-btn>
                  <v-btn
                    :disabled="!cartItems.length"
                    color="secondary"
                    :to="{name: 'Cart'}"
                    class="mr-xl-0 px-3"
                  >
                    Complete Order
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { storeHelper } from '@/utils/helper';
import { CART_DATA, cartNameSpace } from '@/store/modules/cart/types';
import { mapGetters } from 'vuex';
import OrderDetailsTable from '@/components/Cart/OrderDetailsTable';
import CartOrderDetails from '@/components/Cart/OrderDetailsMobile';
import OrderDetailsMobile from '@/components/Cart/OrderDetailsMobile';

export default {
  name: 'CartDetails',
  components: { OrderDetailsMobile, OrderDetailsTable, CartOrderDetails },

  computed:{
    ...mapGetters({
      cartItems: storeHelper(cartNameSpace, CART_DATA),
    })
  },
};
</script>

<style lang="scss" scoped>

.button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  flex-wrap: wrap;
}

@media screen and (max-width: 959px) {
  .button-container {
    max-width: 800px;
    margin: 0 auto;
  }
}

.back-btn {
  min-width: 50px !important;
}
</style>
