<template>
  <div>
    <div
      v-if="!!cartItems?.length"
      class="d-flex flex-column gap-4 carts-container"
    >
      <CartItem
        v-for="(item, index) in cartItems"
        :key="'cart'+ index"
        :cart-item="item"
      />
      <v-divider />
      <h6 class="total">
        <span>
          Subtotal ({{ cartItems?.length }} {{ cartItems?.length > 1 ? 'items' : 'item' }})
        </span>
        <span>
          {{ subTotalAmount | currency }}
        </span>
      </h6>
    </div>
    <div v-else>
      <h6 class="text-h6 text-center mt-5">
        No item added
      </h6>
    </div>
  </div>
</template>


<script>
import { computed } from 'vue';
  import CartItem from '@/components/Cart/CartItem';
import store from '@/store';
import { CART_DATA, cartNameSpace } from '@/store/modules/cart/types';

  export default {
    name: 'OrderDetailsMobile',
    components: { CartItem },

    props: {
      cartItems: {
        type: Array,
        required: true,
      }
    },

    setup(props) {

      const subTotalAmount = computed(() => {
        return props.cartItems?.reduce((initial, current) => initial + current.price * current.quantity, 0);
      });

      return {
        subTotalAmount,
      }
    }
  }
</script>

<style lang="scss" scoped>
$text-color: #3c3c3e;

.carts-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  min-height: 30vh;
}

.total {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 0.9rem;
  font-weight: 500;
  color: $text-color;
  letter-spacing: 0.35px;
  text-transform: uppercase;
}

</style>
