<template>
  <div>
    <div class="cart-item-wrapper">
      <div class="d-flex gap-3 flex-column">
        <div class="product-container">
          <!--image -->
          <div>
            <img
              :src="cartItem.thumbnail || defaultImage"
              :alt="cartItem.name"
              class="product-img"
              @click="gotoProductDetails"
            >
          </div>
          <!--image -->
          <!-- product info -->
          <div class="cartItem-info-wrapper">
            <div class="product-name-wrapper mb-1">
              <h4
                class="product-name"
                @click="gotoProductDetails"
              >
                {{ cartItem.full_name }}
              </h4>
              <i>
                <v-icon
                  color="error"
                  @click="removeItem(cartItem)"
                >
                  mdi-minus-circle
                  <span class="d-sr-only">Remove cartItem</span>
                </v-icon>
              </i>
            </div>

            <div class="d-flex align-center justify-space-between">
              <div class="button-group">
                <button
                  :disabled="cartItem.quantity <= 1"
                  type="button"
                  name="button"
                  class="btn-minus"
                  @click="subtractQuantity(cartItem)"
                >
                  -
                </button>
                <span class="product-quantity">
                  {{ cartItem.quantity }}
                </span>
                <button
                  :disabled="cartItem.quantity >= cartItem.stock || !cartItem.stock"
                  type="button"
                  name="button"
                  class="btn-plus"
                  @click="addQuantity(cartItem)"
                >
                  +
                </button>
              </div>
              <h6 class="product-price">
                {{ cartItem.price | currency }}
              </h6>
            </div>
          </div>
          <!-- product info -->
        </div>
        <v-divider />
        <h6 class="product-subTotal">
          <span>Subtotal ({{ cartItem.quantity }} {{ cartItem.quantity > 1 ? 'items' : 'item' }})</span>
          <span>
            {{ subTotal | currency }}
          </span>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue';
import { API_BASE_URL, storeHelper } from '@/utils/helper';
import { CART_ADD, CART_REMOVE, cartNameSpace } from '@/store/modules/cart/types';
import router from '@/router';
import store from '@/store';

  export default {
    name: 'CartItem',
    props: {
      cartItem: {
        required: true,
        type: Object,
      },
    },

    setup (props) {
      const state = reactive({
        defaultImage: `${API_BASE_URL}/storage/no-images.jpg`,
        item: props.cartItem,
      });

      let subTotal = computed(() => {
        return props.cartItem.quantity * props.cartItem.price;
      });

      const actions = {
        setProduct: (product) => store.dispatch('product/setProduct', product),
        addQuantity: (product) => store.dispatch(storeHelper(cartNameSpace, CART_ADD), {item: product}),
        subtractQuantity: (product) => store.dispatch(storeHelper(cartNameSpace, CART_REMOVE), {type: 'update', payload: product}),
        removeItem: (product) => store.dispatch(`${cartNameSpace}/${CART_REMOVE}`, { payload: product, type: 'remove' }),
      }

      function addQuantity (item) {
        actions.addQuantity(item);
      }

      function subtractQuantity (item) {
        actions.subtractQuantity(item);
      }

      function removeItem(item) {
        actions.removeItem(item);
      }

      async function gotoProductDetails () {
        await actions.setProduct(props.cartItem);

        const urlToGo = {
          name: 'ProductSingle',
          params: {
            variation : props.cartItem.slug,
            product: props.cartItem.product.slug,
            vendor: props.cartItem.product.shop.slug,
          }
        };

       await router.push(urlToGo);
      }

      return {
        ...toRefs(state),
        subTotal,
        addQuantity,
        subtractQuantity,
        gotoProductDetails,
        removeItem,
      };
    }
  }


</script>

<style lang="scss" scoped>

$text-color: #3c3c3e;

.cart-item-wrapper {
  margin-bottom: 2rem;
  min-height: 160px;
}

.product-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 1rem;
  padding-inline: 0;
}

.product-img {
  width: 30vw;
  min-width: 100px;
  height: 100px;
  border-radius: 5px;
  object-fit: contain;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.item-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-name-wrapper {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 5px;
}
.product-name {
  font-size: 0.9rem;
  color: $text-color;
  line-height: 1.5;
  letter-spacing: 0.1px;
  font-weight: 500;
}

.product-price {
  font-size: 0.9rem;
  color: $text-color;
  font-weight: 500;
}

.button-group {
  display: flex;
  align-items: center;
  height: 32px;
  gap: 10px;

  button {
    border: none;
    height: 28px;
    width: 28px;
    border-radius: 4px;
    font-size: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      background-color: rgba(0,0,0,.12);
      color: rgba(0,0,0,.26);
      pointer-events: none;
      border: none;
    }

    &.btn-plus {
      background-color: var(--primary-color);
      color: #fff;
    }

    &.btn-minus {
      background-color: #b31717;
      color: #fff;
    }
  }
}

.product-quantity {
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-appearance: none;
  text-align: center;
  color: #43484D;
  font-weight: 300;
  border: 1px solid #E1E8EE;
  border-radius: 4px;
}

.product-subTotal {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  color: $text-color;
}
</style>
