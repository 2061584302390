<template>
  <v-simple-table
    class="cart-item-table"
    dense
  >
    <thead>
      <tr>
        <th>#</th>
        <th>image</th>
        <th>Product Details</th>
        <th class="text-center">
          Price
        </th>
        <th>Qty</th>
        <th class="text-right">
          Sub Total
        </th>
        <th />
      </tr>
    </thead>
    <tfoot>
      <tr class="cart-footer">
        <th
          colspan="5"
          class="text-right no-border"
        >
          <span class="fs-15">Sub Total
            (<small>{{ cartItems.length }} Item{{ cartItems.length > 1 ? 's' : '' }}</small>)
          </span>
        </th>
        <th
          colspan="1"
          class="text-right no-border"
        >
          <span class="fs-15">
            {{ getSubTotal(cartItems) | currency }}
          </span>
        </th>
      </tr>
    </tfoot>
    <tbody>
      <tr v-if="!cartItems.length">
        <td
          class="no-border text-center"
          colspan="12"
        >
          <h3 class="ma-3">
            No Item added
          </h3>
        </td>
      </tr>
      <tr
        v-for="(item, index ) in cartItems"
        v-else
        :key="'cart'+ index"
      >
        <td>{{ index + 1 }}</td>
        <td class="product-image">
          <img
            v-if="item.thumbnail"
            class="cursor-pointer"
            :alt="item.name"
            :src="item.thumbnail"
            @click="selectProductToDetailsPage(item)"
          >
        </td>
        <td style="width: 45%; white-space: normal">
          <h4
            class="cursor-pointer"
            @click="selectProductToDetailsPage(item)"
          >
            {{ item.full_name }}
          </h4>
        </td>
        <td class="text-center">
          {{ item.price | currency }}
        </td>
        <td class="text-center">
          <div class="wrapper">
            <button
              :disabled="item.quantity <= 1"
              class="btn btn--minus flex-center"
              type="button"
              name="button"
              @click="updateTableData('minus', item)"
            >
              -
            </button>
            <input
              v-model.number="item.quantity"
              readonly
              class="quantity"
              type="text"
              name="name"
            >
            <button
              :disabled="item.quantity >= item.stock || !item.stock"
              class="btn btn--plus flex-center"
              type="button"
              name="button"
              @click="updateTableData('plus', item)"
            >
              +
            </button>
          </div>
        </td>
        <td class="text-right">
          {{ (item.quantity * item.price) | currency }}
        </td>
        <td class="text-center">
          <v-icon
            style="color: #b01010"
            @click="updateTableData('remove', item)"
          >
            mdi-delete
          </v-icon>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { API_BASE_URL, storeHelper } from '@/utils/helper';
import { CART_ADD, CART_REMOVE, cartNameSpace } from '@/store/modules/cart/types';
import router from '@/router';
import { mapActions } from 'vuex';

export default {
  name: 'OrderDetailsTable',
  props: {
    cartItems: {
      type: Array
    }
  },
  data: (props) => ({
    defaultImage: `${API_BASE_URL}/storage/no-images.jpg`,
    carts: props.cartItems,
  }),
  methods: {
    ...mapActions({
      setProduct: 'product/setProduct'
    }),
    updateTableData(type, item) {

      switch (type) {
        case 'plus':
          this.$store.dispatch(storeHelper(cartNameSpace, CART_ADD), { item, });
          break;

        case 'minus' :
          this.$store.dispatch(storeHelper(cartNameSpace, CART_REMOVE), { type: 'update', payload: item });
          break;

        case 'remove':
          this.$store.dispatch(storeHelper(cartNameSpace, CART_REMOVE), { type: 'remove', payload: item });
          break;
      }
    },

    getSubTotal(carts) {
      return carts.reduce((initial, current) => initial + current.price * current.quantity, 0);
    },

    selectProductToDetailsPage(item) {

      this.setProduct(item);

      const urlToGo = {
        name: 'ProductSingle',
        params: {
          variation : item.slug,
          product:item.product.slug,
          vendor:item.product.shop.slug
        }
      };

      return router
        .push(urlToGo)
        .catch(()=>{})
        ;
    }
  },
};
</script>

<style lang='scss' scoped>

.cart-item-table {
  //display: table;
  //width: 100%;
  //border-spacing: 0;

  tbody {
    width: 100%;
  }

  thead {
    background-color: #202e82;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    th {
      color: #ffffff !important;
      text-transform: uppercase;
      font-size: 16px;
    }

    th, td {
      padding: 10px;
      border-spacing: 0;
      white-space: nowrap;
    }
  }

  tr {
    td, th {
      white-space: nowrap;
      vertical-align: middle;
      padding: 10px;
      border-bottom: 1px solid #e3e3e3;
      border-right: 1px solid #e3e3e3;
      border-left: 1px solid #e3e3e3;
    }

    &:nth-child(even) {
      //background-color: #e3e3e3;
    }
  }

  .product-image {
    width: 60px;
    height: 75px;

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  .product-short-details {
      display: flex;
      //flex-direction: row-reverse;
      column-gap: 10px;

    .product-attribute {
      position: relative;

      &:after {
        content: "|";
        margin-left: 10px;
      }

      &:last-of-type {
        &:after {
          content: "";
        }
      }
    }
  }
}
.wrapper {
  height: 36px;
  display: flex;
}
.quantity {
  -webkit-appearance: none;
  text-align: center;
  width: 30px;
  //border-radius: 6px;
  font-size: 16px;
  color: #43484D;
  font-weight: 300;
  border: 1px solid #E1E8EE;
}

.btn {
  border: 1px solid #fff;
  background: #3f51b4;
  width: 30px;
  border-radius: 6px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}
.btn--plus {
  margin-left: 10px;

  &:disabled {
    background-color: rgba(0,0,0,.12);
    color: rgba(0,0,0,.26);
    pointer-events: none;
    border: none;
  }
}
.ht-button span.v-btn__content {
  line-height: 30px !important;
}
.btn--minus {
  margin-right: 10px;
  background: #b31717;
}
.no-border {
  border: 0 !important;
}
.fs-15 {
  font-size: 15px;
}
button:focus,
input:focus {
  outline:0;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
